import React, { ReactChild } from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import Img, { FluidObject } from "gatsby-image";

import { FaGithub, FaTwitter, FaLinkedin, FaGlobe } from "react-icons/fa";

import "@styles/about.scss";

const teamData = [
  {
    name: "Yoegsh Kotadiya",
    socialLink: "@yoeshkotadiya",
    github: "https://github.com/yogeshkotadiya",
    twitter: "https://twitter.com/yogeshkotadiya",
    linkedIn: "https://www.linkedin.com/in/yogeshkotadiya/",
    web: "https://yogeshkotadiya.com",
  },
  {
    name: "Himanshu Sandha",
    socialLink: "@himanshusandha",
    github: "https://github.com/himanshusandha",
    twitter: "https://twitter.com/himanshusandha",
    linkedIn: "https://www.linkedin.com/in/himanshu-sandha-839819154",
  },
];

const TeamMemberSocialLink = (props: {
  link: string;
  children: ReactChild;
}) => (
  <li>
    <a
      href={props.link}
      target="_blank"
      rel="noopener noreferrer"
      className="socail-link"
    >
      {props.children}
    </a>
  </li>
);

export interface TeamMemberProps {
  name: string;
  socialLink?: string;
  description?: string;
  image?: FluidObject;
  github?: string;
  twitter?: string;
  linkedIn?: string;
  web?: string;
}

const TeamMember: React.FC<TeamMemberProps> = props => {
  return (
    <div className="team-member">
      <div className="team-member__image">
        <Img fluid={props.image} alt={`Image of ${props.name}`} />
      </div>
      <div className="team-member__info">
        <div className="team-member__info--head">
          <h2 className="team-member__info--head--heading">{props.name}</h2>
          <h3 className="team-member__info--head--social">
            {props.socialLink}
          </h3>
        </div>
        <div className="team-member__info--body">
          <p>{props.description}</p>
        </div>
        <div className="team-member__info-social">
          <ul className="team-member__info-social-list">
            {props.github && (
              <TeamMemberSocialLink link={props.github}>
                <FaGithub />
              </TeamMemberSocialLink>
            )}
            {props.twitter && (
              <TeamMemberSocialLink link={props.twitter}>
                <FaTwitter />
              </TeamMemberSocialLink>
            )}
            {props.linkedIn && (
              <TeamMemberSocialLink link={props.linkedIn}>
                <FaLinkedin />
              </TeamMemberSocialLink>
            )}
            {props.web && (
              <TeamMemberSocialLink link={props.web}>
                <FaGlobe />
              </TeamMemberSocialLink>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

const Team: React.FC = () => {
  const data = useStaticQuery(PageQuery);

  return (
    <>
      <div className="team-container container">
        <div className="team">
          <div className="team-heading">
            <h2 className="heading">
              Brains behind <span>Fregmaa</span>
            </h2>
          </div>
          <TeamMember
            name={teamData[0].name}
            socialLink={teamData[0].socialLink}
            github={teamData[0].github}
            twitter={teamData[0].twitter}
            linkedIn={teamData[0].linkedIn}
            web={teamData[0].web}
            image={data.yogesh.childImageSharp.fluid}
            description="Yogesh is passionate about web and programming. He has been developing in Javascript for several years and also loves to contribute to Open Source.  His primary focus is on React and Web Performace."
          />
          <TeamMember
            name={teamData[1].name}
            socialLink={teamData[1].socialLink}
            github={teamData[1].github}
            twitter={teamData[1].twitter}
            linkedIn={teamData[1].linkedIn}
            web={teamData[1].web}
            image={data.himanshu.childImageSharp.fluid}
            description="Himanshu is a mobile tech enthusiast, been developing Web and Android applications and has a keen eye for details."
          />
        </div>
      </div>
    </>
  );
};

export default Team;

const PageQuery = graphql`
  query {
    yogesh: file(relativePath: { eq: "authors/yogesh.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, maxHeight: 800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    himanshu: file(relativePath: { eq: "authors/himanshu.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, maxHeight: 800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
