import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

import PageHead from "@Components/PageHead";
import Team from "@Components/Team";

import "../styles/about.scss";

function About() {
  const data = useStaticQuery(PageQuery);
  return (
    <>
      <PageHead backgroundImg={data.aboutHero.childImageSharp.fluid}>
        <h2 className="head-title">
          Who is <span>Fregmaa</span>?
        </h2>
      </PageHead>
      <Team />
      <div className="joinus">
        <Link to="/careers">Join Us</Link>
      </div>
    </>
  );
}

export default About;

export const PageQuery = graphql`
  query {
    aboutHero: file(relativePath: { eq: "aboutHero.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
